import $ from 'jquery';
import Dropdown from 'bootstrap/js/dist/dropdown';
import * as bootstrap from 'bootstrap';

import { renderRedemptionModal } from '/js/redeem.js';
import { logoutUser, userData, showSettings } from '/js/accounts.js';
import { renderLibrary } from '/js/library.js';

// XSS protection
function escapeHtml(text) {
  return $("<div>").text(text).html();
}

function userOptionsHtml() {
  // Define and Sanitize the variables
  const userGivenName = escapeHtml(userData.givenName);
  const userFamilyName = escapeHtml(userData.familyName);
  
  // Create elements
  const btnGroup = $('<div class="btn-group"></div>');
  const btnDropdown = $(`<button type="button" style="color: white !important; padding-left: 0.5rem; padding-right: 0.5rem;" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-person-fill"></i> ${userGivenName}</button>`);
  const dropdownMenu = $('<ul class="dropdown-menu"></ul>');
  const settingsItem = $('<li><a class="dropdown-item">Settings</a></li>');
  const helpItem = $('<li><a class="dropdown-item">Help</a></li>');
  const refreshItem = $('<li><a class="dropdown-item">Refresh</a></li>');
  const divider1 = $('<li><hr class="dropdown-divider"></li>');
  let adminItem = $('<li><a href="https://admin.stagevault.com" class="dropdown-item">Administration</a></li>');
  if (window.location.href.includes("dev-proxy.net")) {
    adminItem = $('<li><a href="https://stagevault-admin.dev-proxy.net" class="dropdown-item">Administration</a></li>');
  }
  const divider2 = $('<li><hr class="dropdown-divider"></li>');
  const signOutItem = $('<li><a class="dropdown-item">Sign Out</a></li>');
  
  // Merge Elements
  if (userData.admin) {
    dropdownMenu.append(settingsItem, helpItem, refreshItem, divider1, adminItem, divider2, signOutItem);
  } else {
    dropdownMenu.append(settingsItem, helpItem, refreshItem, divider1, signOutItem);
  }
  btnGroup.append(btnDropdown, dropdownMenu);
  
  // Add listeners
  refreshItem.on('click', function (event) {
	event.preventDefault();
	showLoader();
	window.location.reload();
  });
  
  settingsItem.on('click', function (event) {
    showSettings();
  });
  
  helpItem.on('click', function (event) {
    window.location.href = "mailto:support@stagevault.com";
  });
  
  signOutItem.on('click', function (event) {
	event.preventDefault();
	showLoader();
	logoutUser();
  });
  
  return btnGroup;
}

export function navBarHtml(pageTitle) {

  // Create elements
  const navbar = $('<nav class="navbar navbar-dark sticky-top bg-danger-subtle"></nav>');
  const container = $('<div class="container"></div>');
  const hstack = $('<div class="hstack w-100 justify-content-between"></div>');
  const brand = $('<span class="navbar-brand mb-0 h1"></span>').text(pageTitle);
  const actions = $('<div class="hstack gap-2"></div>');
  const redeemButton = $('<a class="btn text-warning"><i class="bi bi-plus-lg"></i> Redeem</a>');
  const userOptions = userOptionsHtml();

  // Assemble the elements
  actions.append(redeemButton, userOptions);
  hstack.append(brand, actions);
  container.append(hstack);
  navbar.append(container);
  
  // Find dropdown element and initialize
  let dropdownElement = actions.find('.dropdown-toggle')[0];
  let dropdown = new Dropdown(dropdownElement);

  // Add the click event listener for the redeem button
  redeemButton.on('click', function (event) {
	event.preventDefault();
	renderRedemptionModal();
	const redemptionModal = document.getElementById('redemptionModal');
	const modal = new bootstrap.Modal(redemptionModal);
	modal.show();
  });

  return navbar;
}

export function watchNavBarHtml(showTitle) {
  // Build the HTML elements
  const nav = $('<nav class="navbar navbar-dark sticky-top bg-danger-subtle" style="height: 54px !important;"></nav>');
  const container = $('<div class="container"></div>');
  const hStack = $('<div class="d-flex w-100 justify-content-between align-items-center"></div>');
  const backButton = $('<a class="btn text-light"><i class="bi bi-chevron-left me-1 fw-bold"></i>My Vault</a>');
  const title = $('<span class="navbar-brand mb-0 h1 text-truncate" style="flex-grow: 1; text-align: center; max-width: calc(100% - 250px);"></span>').text(showTitle);
  const userOptions = userOptionsHtml();
  
  // Add selectors
  backButton.on('click', function() {
	renderLibrary();
  });
  
  // Assemble the elements
  hStack.append(backButton, title, userOptions);
  container.append(hStack);
  nav.append(container);
  
  return nav;
}

export var loader;

export function showLoader() {
	loader = $('<div id="loading-screen" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9999;" class="bg-danger-subtle"><div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"><div class="spinner-border text-white" role="status"><span class="visually-hidden">Loading...</span></div></div></div>');
	$('#app').append(loader);
}

export function hideLoader() {
  $('#loading-screen').remove();
}

